import {Link, Navigate, Outlet} from "react-router-dom";
import {ReactSVG} from "react-svg";
import "./DefaultLayout.scss";
import {useEffect, useState} from "react";
import icon from "../assets/WebvalueIcon.webp";
import placeholderPFP from "../assets/placeholderPFP.webp";
import adminNavItemsData from "../data/adminNavItemsData.js";

export default function AdminLayout() {

    return  <Navigate to="/" />

    const [navItems, setNavItems] = useState([]);
    const [menuOpenClasses, setMenuOpenClasses] = useState("");
    const [menuOpen, setMenuOpen] = useState();
    const [notifOpen, setNotifOpen] = useState();
    const [notifClasses, setNotifClasses] = useState();
    const [accountOpen, setAccountOpen] = useState();
    const [accountPopup, setAccountPopup] = useState();

    useEffect(()=>{
        setNotifClasses("notificationsPopup");
        setNavItems(adminNavItemsData);
        setMenuOpenClasses("homePage");
        setAccountPopup(<i className="fa-solid fa-chevron-down"></i>)
        setMenuOpen(false);
        setNotifOpen(false);
        setAccountOpen(false);
    }, []);

    const navItemsToBeRendered = navItems.map(navItem =>{
        return(
            <Link key={navItem.text} className="LinkElement" to={"/" + navItem.element}>
                <li className={`nav__item ${(window.location.pathname === "/" + navItem.element) && "selected"}`}>
                    <div className="nav__item__wrapper">
                        <ReactSVG className="nav__item__icon" src={navItem.icon} alt="" />
                        <label className="nav__item__title">{navItem.text}</label>
                    </div>
                </li>
            </Link>
        );
        
    })

    const mobilenavItemsToBeRendered = navItems.map(navItem =>{
        let isSelected = false;
        if(("/" + navItem.element) === window.location.pathname){
            isSelected = true;
        }
        
        return(
            <Link key={navItem.text} className="LinkElement" to={"/" + navItem.element}>
                <li className={`mobilenav__ul__li ${isSelected && "mobilenav__ul__li--selected"}`}>
                    <ReactSVG className="mobilenav__ul__li__icon" src={navItem.icon} alt="" />
                    <label className="mobilenav__ul__li__title">{navItem.text}</label>
                </li>
            </Link>
        );
    })


    const onMenuClick = () =>{

        if(!menuOpen){
            setMenuOpenClasses("homePage menuOpen");
        }else{
            setMenuOpenClasses("homePage");
        }

        setMenuOpen(!menuOpen);
    }

    const onAccountClick = () =>{
        if(!accountOpen){
            setAccountPopup(
                <>
                    <i className="fa-solid fa-chevron-up"></i>
                    <div className="account__popup">
                        <h2 className="account__popup__user">Ingelogd als </h2>
                        <h2 className="account__popup__user">username</h2>
                        <button onClick={onLogout} className="account__popup__btn">Logout</button>
                    </div>
                </>
            )
        }else{
            setAccountPopup(<i className="fa-solid fa-chevron-down"></i>)
        }
        setAccountOpen(!accountOpen)
    }

    const onLogout = (ev) =>{
        ev.preventDefault()

    }

    return(
        <div className={menuOpenClasses}>
            <div className="logo">
                <img src={icon} alt="webvalue icon" />
                <h1>Webvalue Status</h1>
            </div>
            <header className="header">
                <div>
                    <div onClick={onAccountClick} className="account">
                        <figure>
                            <img src={placeholderPFP} alt="" />
                        </figure>
                        <p>Mijn account</p>
                        {accountPopup}
                    </div>
                </div>
            </header>
            <nav className="nav">
                <ul className="nav__list">
                    {navItemsToBeRendered}
                </ul>
            </nav>
            <Outlet />
            <div className="navfiller do_not_remove_thanks"></div>
            <nav className="mobilenav">
                <ul className="mobilenav__ul">
                    {mobilenavItemsToBeRendered}
                </ul>
            </nav>
        </div>
    );
}
