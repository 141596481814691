import axios from "axios";

const axiosClient = axios.create({
    baseURL: `https://julian.klantportaal.webvalue.nl/`
})

axiosClient.interceptors.request.use((config) =>{
    const token = localStorage.getItem('ACCESS_TOKEN')
    config.headers.Authorization = `Bearer ${token}`
    config.headers['Content-Type'] = 'application/json';
    config.headers['Access-Control-Allow-Origin'] = '*, *';
    return config;
})

axiosClient.interceptors.response.use((response) =>{
    return response;
}, (error) =>{
    try {
        const {response} = error;
        if(response.status === 401){
            localStorage.removeItem('ACCESS_TOKEN')
        }
    } catch (e) {

    }

    throw error;
})

export  default  axiosClient;
