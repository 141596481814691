import "./AkkoordNodig.scss";
import "../../../../../scss/basics.scss";
import { useEffect, useState } from "react";
import SignaturePopup from "../../../../../components/SignaturePopup/SignaturePopup.jsx";



const AkkoordNodig = () =>{

    const [signaturePopupOpen, setSignaturePopupOpen] = useState();

    useEffect(()=>{
        setSignaturePopupOpen(false);
    },[])

    const switchSignaturePopupOpen = () =>{
        setSignaturePopupOpen(!signaturePopupOpen);
    }
    return(
        <div className="akkoordNodig">
            <SignaturePopup signaturePopupOpen={signaturePopupOpen} switchSignaturePopupOpen={switchSignaturePopupOpen}/>
            <p className="akkoordNodig__p">Akkoord nodig</p>
            <button className="btn btn--projectdetails" onClick={switchSignaturePopupOpen}>Ik ga akkoord</button>
        </div>
    );
}

export default AkkoordNodig;
