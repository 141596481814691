import "./Berichten.scss";
import { useEffect, useState } from "react";
import calculatedPercentage from "../../helpers/calculatedPercentage";
import { filterArchived, filterImportant, filterUnread } from "../../helpers/FilterBerichten";
import Bericht from "./Bericht/Bericht";
import axios from "axios";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";

const Berichten = () => {
    const {token} = useStateContext();
    const [addberichtpopup, setAddberichtpopup] = useState(false);

    const [updatesFromApi, setUpdatesFromApi] = useState();
    const [amountToBeRendered, setAmountToBeRendered] = useState(5);
    const [berichten, setBerichten] = useState();
    const [baseBerichten, setBaseBerichten] = useState();

    const [importantFilter, setImportantFilter] = useState(false);
    const [unreadFilter, setUnreadFilter] = useState(false);
    const [archivedFilter, setArchivedFilter] = useState(false);

    const fetchUpdates = () => {
        axiosClient({
            method: 'get',
            url: '/api/v1/Updates',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setUpdatesFromApi(response.data);
          });
    };
    useEffect(() => {
        fetchUpdates();
    }, []);

    useEffect(()=>{
        if(updatesFromApi){
            setBaseBerichten(updatesFromApi.updates);
            setBerichten(updatesFromApi.updates);
        }
    },[updatesFromApi])

    useEffect(()=>{
        if(!updatesFromApi)return;
        if(amountToBeRendered > baseBerichten.length){
            setAmountToBeRendered(baseBerichten.length);
        }
    },[amountToBeRendered])

    useEffect(()=>{
        if(!updatesFromApi)return;
        setAmountToBeRendered(berichten.length);
    },[berichten])

    useEffect(()=>{
        if(!updatesFromApi)return;
        let _berichten = baseBerichten;
        if(importantFilter){
            _berichten = filterImportant(_berichten);
        }
        if(unreadFilter){
            _berichten = filterUnread(_berichten);
        }
        if(archivedFilter){
            _berichten = filterArchived(_berichten);
        }
        setBerichten(_berichten);
    },[importantFilter, unreadFilter, archivedFilter, baseBerichten])

    
    const loadMoreMessages = () =>{
        setAmountToBeRendered(amountToBeRendered + 5);
    }

    // if(!berichten) return <p>Loading...</p>;
    let berichtenToBeRendered = [];
    if(berichten){
        berichtenToBeRendered = berichten.map((bericht, index) =>{
            if (!archivedFilter && bericht.archived) return;
            if(!(index > (amountToBeRendered - 1))){
                return <Bericht bericht={bericht} baseBerichten={baseBerichten} setBaseBerichten={setBaseBerichten}/>;
            }
            return;
        })
    }

    // const addUpdate = (ev) =>{
    //     ev.preventDefault();
    //     axios({
    //         method: 'post',
    //         url: '/api/v1/Updates',
    //         params: {
    //             apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
    //             token: 'TempTokenWebvalue3',
    //             submit: 'submit'
    //         },
    //         ProjectId: 4760,
    //         Titel: "titelst",
    //         Bericht: "bericht",
    //     }).then(function (response) {
    //         console.log(response)
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    // };

    

    return(
        <>
        {/* <button onClick={()=>setAddberichtpopup(!addberichtpopup)} className="addberichtbutton">Nieuw bericht!</button>
        {addberichtpopup && <div className="addberichtpopup">
            <h3>Nieuw bericht</h3>
            <form onSubmit={(ev)=>addUpdate(ev)}>
                <div>
                    <label htmlFor="">Titel</label>
                    <input type="text" />
                </div>
                <div>
                    <label htmlFor="">Bericht</label>
                    <input type="text" />
                </div>
                <button>submit</button>
            </form>
        </div>} */}
        <section className="berichten">
            <h1 className="berichten__title main-title">Updates</h1>
            <header className="berichten__header">
                
                <div className="berichten__header__filterwrapper">
                    {!archivedFilter && <><button onClick={()=>setImportantFilter(!importantFilter)} className={`btn btn--bluewhite ${importantFilter && "btn--bluewhite--selected"}`}><i class="fa-solid fa-triangle-exclamation"></i>Important</button>
                    <button onClick={()=>setUnreadFilter(!unreadFilter)} className={`btn btn--bluewhite ${unreadFilter && "btn--bluewhite--selected"}`}><i class="fa-solid fa-eye-slash"></i>Unread</button></>}
                </div>
                {!archivedFilter && <button onClick={()=>setArchivedFilter(!archivedFilter)} className="berichten__header__archivebutton">Bekijk archief</button>}
                {archivedFilter && <button onClick={()=>setArchivedFilter(!archivedFilter)} className="berichten__header__archivebutton">Terug naar huidige updates</button>}
            </header>
            {!berichten && <p>Loading...</p>}
            {berichten &&<div className="berichten__berichtwrapper">
            {berichtenToBeRendered}
            </div>}
            {berichten &&
            <footer className="berichten__footer">
            <p className="berichten__footer__amountshown">Toont {amountToBeRendered} van de {berichten.length} berichten</p>
            <div className="berichten__footer__amountprogressbar">
                <div className="berichten__footer__amountprogressbar--progress" style={{width: `${calculatedPercentage(amountToBeRendered, berichten.length)}%`}}></div>
            </div>
            {!(amountToBeRendered >= berichten.length) &&
                <button onClick={loadMoreMessages} className="berichten__footer__loadmorebutton btn">Meer updates inladen</button>
            }

            </footer>
            }
        </section>
        </>
    );
}

export default Berichten;