import "./ForgotPasswordPopup.scss";

const ForgotPasswordPopup = ({changePopupOpen, popupOpen}) =>{
    const onForgotPasswordSubmit = () =>{
        alert("super echt veranderd");
        changePopupOpen();
    }

    if(popupOpen){
        return(
            <div className="forgotPWPopup">
                <form className="forgotPWPopup__form" onSubmit={(e) => {e.preventDefault()}} action={() => onForgotPasswordSubmit}>
                    <div className="forgotPWHeader">
                        <h1 className="forgotPWHeader__title">Wachtwoord vergeten?</h1>
                    </div>
                    <div className="forgotPWPopup__form__inputWrapper">
                        <label className="forgotPWPopup__form__inputWrapper__label" htmlFor="email">email</label>
                        <input className="forgotPWPopup__form__inputWrapper__input" type="text" name="email" id="email"/>
                    </div>
                    <div className="forgotPWPopup__form__buttonWrapper">
                        <button onClick={onForgotPasswordSubmit} className="forgotPWPopup__form__buttonWrapper__resetButton">Reset wachtwoord</button>
                        <button onClick={changePopupOpen} className="forgotPWPopup__form__buttonWrapper__backButton">Terug</button>
                    </div>
                </form>
            </div>
        );
    }else{
        return <></>;
    }

}

export default ForgotPasswordPopup;

/*
const [popupOpen, setPopupOpen] = useState();

    useEffect(()=>{
        setPopupOpen(false);
    },[])

    const changePopupOpen = () =>{
        setPopupOpen(!popupOpen);
    }

return  <ForgotPasswordPopup popupOpen={popupOpen} changePopupOpen={changePopupOpen}/>;
 */
