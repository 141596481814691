import { useState } from "react";
import { dateDifferenceChecker } from "../../../helpers/dateDifferenceChecker";
import SelectedBericht from "../../SelectedBericht/SelectedBericht";
import "./Bericht.scss";
import databaseIntToBool from "../../../helpers/databaseIntToBool";

const Bericht = ( {bericht, setBaseBerichten, baseBerichten} ) => {
    const [selectedMessageOpen, setSelectedMessageOpen] = useState(false);


    return(
        <>
        {selectedMessageOpen &&  <SelectedBericht setSelectedMessageOpen={setSelectedMessageOpen} bericht={bericht} baseBerichten={baseBerichten} setBaseBerichten={setBaseBerichten}/>}
        <div className="berichten__bericht">
            <div onClick={()=>setSelectedMessageOpen(!selectedMessageOpen)} className="berichten__bericht__textwrapper">
                <figure className="berichten__bericht__textwrapper__figure">
                    <p className="berichten__bericht__textwrapper__figure__uploadedby" >Van {bericht.user || "unknown"}</p>
                    <img className="berichten__bericht__textwrapper__figure--img" src="/src/assets/placeholderPFP.webp" alt=""/>
                </figure>
                <div className="berichten__bericht__textwrapper__div">
                    <p className="berichten__bericht__textwrapper__div--title">{bericht.message}</p>
                    <p className="berichten__bericht__textwrapper__div--date">{dateDifferenceChecker(bericht.created_date)}</p>
                    {bericht.important && <i class="fa-solid fa-triangle-exclamation berichten__bericht__textwrapper__div--important"></i>}
                </div>
            </div>
            <div className="berichten__bericht__btnwrapper">
                <button className="berichten__bericht__btnwrapper__btn">
                    <i class="fa-regular fa-comment"></i>
                </button>
                <button onClick={()=>setBaseBerichten(baseBerichten.map(_bericht => _bericht.id === bericht.id ? {..._bericht, read: !bericht.read} : _bericht))} className="berichten__bericht__btnwrapper__btn">
                    {!bericht.read && <i class="fa-regular fa-eye-slash"></i>}
                    {bericht.read && <i class="fa-regular fa-eye"></i>}
                </button>
                <button onClick={()=>setBaseBerichten(baseBerichten.map(_bericht => _bericht.id === bericht.id ? {..._bericht, archived: !bericht.archived} : _bericht))} className="berichten__bericht__btnwrapper__btn">
                    {!bericht.archived && <i class="fa-regular fa-folder"></i>}
                    {bericht.archived && <i class="fa-solid fa-folder"></i>}
                </button>
                <button onClick={()=>setSelectedMessageOpen(!selectedMessageOpen)} className="berichten__bericht__btnwrapper__btn">
                    <i class="fa-solid fa-info"></i>
                </button>
            </div>
        </div>
        </>
    );
}

export default Bericht;