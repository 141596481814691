import "./ProgressCircle.scss";
import calculatedPercentage from "../../helpers/calculatedPercentage";

const ProgressCircle = ({project}) => {

    

    const projectPercentage = calculatedPercentage(project.hours_spend, project.hours_total).toFixed(0);

    return(
        <div className="progresscircle">
            <div style={{"--percentage": projectPercentage+"%"}} class={`progresscircle__circle ${(projectPercentage > 100) && 'progresscircle__circle--overtime'}`}>
                <h3 className="progresscircle__circle__title">{projectPercentage}%</h3>
                <h3 className="progresscircle__circle__subtitle">{project.hours_spend.toFixed(2)}/{project.hours_total.toFixed(0)}</h3>
            </div>
            {/* <h2 className="progresscircle__title">Voortgang</h2> */}
        </div>
    );
}

export default ProgressCircle;