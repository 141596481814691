import "./Documenten.scss";
import {useEffect, useRef, useState} from "react";
import calculatedPercentage from "../../helpers/calculatedPercentage.js";
import {filterDocumentsByTitle, filterDocumentsByType, filterDocumentsByUser} from "../../helpers/filterDocuments.js";
import {dateDifferenceChecker} from "../../helpers/dateDifferenceChecker.js";
import {ReactSVG} from "react-svg";
import uploadfilesicon from "../../assets/uploadfilesicon.svg";
import Document from "./Document/Document";
import { extensionChecker } from "../../helpers/extensionChecker";
import axios from "axios";
import { extensionToImage } from "../../helpers/extensionToImage";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";

// https://stackoverflow.com/questions/43013858/how-to-post-a-file-from-a-form-with-axios

const Documenten = () => {
    const {token} = useStateContext();
    const [amountToBeRendered, setAmountToBeRendered] = useState(5);
    const [allDocumentsRendered, setAllDocumentsRendered] = useState(false);
    const [filteredDocuments, setFilteredDocuments] = useState();
    const [userFilterOpen, setUserFilterOpen] = useState(false);
    const [userToBeFiltered, setUserToBeFiltered] = useState("");
    const [typeToBeFiltered, setTypeToBeFiltered] = useState("");
    const [documentsFromApi, setDocumentsFromApi] = useState();
    const searchInputRef = useRef(null);

    const fetchItems = (id) => {
        axiosClient({
            method: 'get',
            url: `/api/v1/Documents/`,
            params: {
                project_id: id,
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            let _documentsFromApi = [];
            for(let item in response.data.documents){
                _documentsFromApi.push(response.data.documents[item]);
            }
            setDocumentsFromApi(_documentsFromApi);
          });
    };
    useEffect(() => {
        fetchItems(4760);
    },[]);

    useEffect(() => {
        if(!documentsFromApi) return;
        setFilteredDocuments(documentsFromApi);
    },[documentsFromApi]);

    useEffect(()=>{     // everything in this useEffect will happen the first time the site renders
        checkIfAllDocumentsRendered()
    },[])
    useEffect(()=>{onSearchDocuments()},[userToBeFiltered]);
    useEffect(()=>{onSearchDocuments()},[typeToBeFiltered]);


    useEffect(()=>{     //everything in this useEffect will happen when it detects a change in amountToBeRendered state
        if(!filteredDocuments) return;
        checkIfAllDocumentsRendered();
        if(amountToBeRendered > filteredDocuments.length){
            setAmountToBeRendered(filteredDocuments.length);
        }
    },[amountToBeRendered])

    useEffect(()=>{
        if(!filteredDocuments) return;
        if (filteredDocuments.length > 5){ setAmountToBeRendered(5)
        }else{ setAmountToBeRendered(filteredDocuments.length) }
    },[filteredDocuments])

    const onSearchDocuments = () =>{
        if(!documentsFromApi) return;
        let filterDocumentsVariable = [];
        filterDocumentsVariable = filterDocumentsByTitle(documentsFromApi, searchInputRef.current.value);
        if (typeToBeFiltered !== ""){ filterDocumentsVariable = filterDocumentsByType(filterDocumentsVariable, typeToBeFiltered) }
        if (userToBeFiltered !== ""){ filterDocumentsVariable = filterDocumentsByUser(filterDocumentsVariable, userToBeFiltered) }
        setFilteredDocuments(filterDocumentsVariable);
    }

    const onTitleSearch = (ev) =>{
        ev.preventDefault()
        onSearchDocuments();
    }

    const loadMoreDocuments = () =>{
        setAmountToBeRendered(amountToBeRendered + 5);
    }

    const checkIfAllDocumentsRendered = () =>{
        if(!filteredDocuments) return;
        if(amountToBeRendered >= filteredDocuments.length){
            setAllDocumentsRendered(true);
        }else{
            setAllDocumentsRendered(false)
        }
    }

    const onUserFilterClicked =(name) =>{
        if(userToBeFiltered === name || name === "name"){
            setUserToBeFiltered("");
            setUserFilterOpen(false);
        }else{
            setUserToBeFiltered(name);
            setUserFilterOpen(false);
        }
    }

    const onTypeFilterClicked = (type) =>{
        if(typeToBeFiltered === type){
            setTypeToBeFiltered("")
        }else{
            setTypeToBeFiltered(type);
        }
    }

    const namesArray = [];
    const typesArray = [];
    if(documentsFromApi){
        if(!documentsFromApi) return;
        documentsFromApi.forEach(document =>{
            if(!namesArray.includes(document.user_id)){
                namesArray.push(document.user_id);
            }
            if(!typesArray.includes(extensionChecker(document.extension))){ 
                typesArray.push(extensionChecker(document.extension));
            }
        });
    }

    const userFiltersToBeRendered = namesArray.map(name =>{
        return(
        <li onClick={()=>onUserFilterClicked(name)} className="userfilterpopup__ul__li">
            <figure className="userfilterpopup__ul__li__figure">
                <img className="userfilterpopup__ul__li__figure--img" src="/src/assets/placeholderPFP.webp" alt=""/>
            </figure>
            <h3 className="userfilterpopup__ul__li__name">{name}</h3>
        </li>
        );
    })
    const typeFiltersToBeRendered = typesArray.map((type, index)=>{
        if (index < 3){
            if(type.toLowerCase() === typeToBeFiltered.toLowerCase()) {
                return (
                    <button onClick={() => onTypeFilterClicked(type.toLowerCase())} className="btn documenten__header__filterwrapper__doctypefilter documenten__header__filterwrapper__doctypefilter--selected">
                        <ReactSVG src={extensionToImage(type.toLowerCase())} className="documenten__header__filterwrapper__doctypefilter--icon"></ReactSVG>
                        <p className="documenten__header__filterwrapper__doctypefilter--text">{type} bestanden</p>
                    </button>
                )
            }
            return (
                <button onClick={() => onTypeFilterClicked(type.toLowerCase())} className="btn documenten__header__filterwrapper__doctypefilter">
                    <ReactSVG src={extensionToImage(type.toLowerCase())} className="documenten__header__filterwrapper__doctypefilter--icon"></ReactSVG>
                    <p className="documenten__header__filterwrapper__doctypefilter--text">{type} bestanden</p>
                </button>
            )
        }
        return;
    })

    if(!filteredDocuments) return <p>Loading...</p>;

    const documentsToBeRendered = filteredDocuments.map((document, index) =>{
        if(!(index > (amountToBeRendered - 1))){
            return <Document document={document} key={index}/>;
        }
        return;
    })

    return(
        <section className="section documenten">
            <header className="documenten__header">
                <h2 className="documenten__header__title main-title">Documenten</h2>
                <form className="documenten__header__form" onSubmit={(ev)=>onTitleSearch(ev)}>
                    <input ref={searchInputRef} placeholder="Zoek naar documenten, berichten of updates" className="documenten__header__form__input" type="text"/>
                    <button onSubmit={()=>onSearchDocuments()} className="documenten__header__form__button">
                        <i className="fa-solid fa-magnifying-glass documenten__header__form__button--icon"></i>
                    </button>
                </form>
                <div className="documenten__header__wrapper">
                    <div className="documenten__header__filterwrapper">
                        {typeFiltersToBeRendered}
                        {!userFilterOpen &&
                            <button onClick={() => setUserFilterOpen(!userFilterOpen)} className="documenten__header__filterwrapper__uploadedbyfilter">
                                <div className="documenten__header__filterwrapper__uploadedbyfilter__figurewrapper">
                                <figure className="documenten__header__filterwrapper__uploadedbyfilter__figure">
                                    <img className="documenten__header__filterwrapper__uploadedbyfilter__figure--img" src="/src/assets/placeholderPFP.webp" alt=""/>
                                </figure>
                                </div>
                                <i className="fa-solid fa-chevron-down documenten__header__filterwrapper__uploadedbyfilter--chevron"></i>
                            </button>
                        }
                        {userFilterOpen &&
                            <div className="documenten__header__filterwrapper__userfilterpopup">
                                <ul className="userfilterpopup__ul">
                                    <li onClick={()=>onUserFilterClicked("name")} className="userfilterpopup__ul__li">
                                        <figure className="userfilterpopup__ul__li__figure">
                                            <img className="userfilterpopup__ul__li__figure--img" src="/src/assets/placeholderPFP.webp" alt=""/>
                                        </figure>
                                        <h3 className="userfilterpopup__ul__li__name">---</h3>
                                    </li>
                                    {userFiltersToBeRendered}
                                </ul>
                            </div>
                        }
                    </div>
                    <button className="btn btn--greenwhite">
                        <ReactSVG src={uploadfilesicon} />
                        Bestand uploaden
                    </button>
                </div>
            </header>
            <div className="documenten__titlewrapper">
                <h2 className="documenten__titlewrapper__title">Overzicht</h2>
            </div>
            
            <div className="documenten__documentwrapper">
            {documentsToBeRendered}
            </div>
            <footer className="documenten__footer">
            <p className="documenten__footer__amountshown">Toont {amountToBeRendered} van de {filteredDocuments.length} documenten</p>
            <div className="documenten__footer__amountprogressbar">
                <div className="documenten__footer__amountprogressbar--progress" style={{width: `${calculatedPercentage(amountToBeRendered, filteredDocuments.length)}%`}}></div>
            </div>
            {!allDocumentsRendered &&
                <button onClick={loadMoreDocuments} className="documenten__footer__loadmorebutton btn">Meer documenten inladen</button>
            }

            </footer>

        </section>
    );
}

export default Documenten;
