import "./SelectedIssue.scss";
import { useEffect, useRef, useState } from "react";
import PlaceholderPFP from "../../assets/placeholderPFP.webp";
import axios from "axios";
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";

const Selectedissue = ({baseIssues, setBaseIssues, issue,  issueId, setSelectedIssueOpen}) => {
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [itemLinesFromApi, setItemLinesFromApi] = useState();
    const msgRef = useRef();
    const {token} = useStateContext();

    const status = ["Ongelezen","in behandeling","Afgerond"]

    const fetchItemLines = () => {
        axiosClient({
            method: 'get',
            url: `/api/v1/ItemLines/${issueId}`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setItemLinesFromApi(response.data);
          });
    };
    useEffect(() => {
        fetchItemLines();
    }, []);

    const onSendMessage = (ev) =>{
        ev.preventDefault();
        const newMessage = {
            apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
            item_id: issueId,
            message: msgRef.current.value,
            document: null,
        }
        msgRef.current.value = "";
        axiosClient({
            method: 'post',
            url: `/api/v1/ItemLines/`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            },
            data: newMessage
        }).then(function (response) {
            console.log(response);
            fetchItemLines();
          })
          .catch(function (error) {
            console.log(error);
            });
    }

    let chatMessagesToBeRendered = [];
    if(issue.chat){
        chatMessagesToBeRendered = issue.chat.map(chatMessage =>{  
            return(
                <div className={`selectedissue__right__chat__message selectedissue__right__chat__message${!chatMessage.klant && "--left"} selectedissue__right__chat__message${chatMessage.klant && "--right"}`}>
                    <header className="selectedissue__right__chat__message__header">
                        <div className="selectedissue__right__chat__message__header__userwrapper">
                            <figure className="selectedissue__right__chat__message__header__userwrapper__figure">
                                <img src={PlaceholderPFP} alt="" />
                            </figure>
                            <p className="selectedissue__right__chat__message__header__userwrapper__by">{chatMessage.by}</p>
                        </div>
                        <p className="selectedissue__right__chat__message__header__date">{chatMessage.date}</p>
                    </header>
                    <div className="selectedissue__right__chat__message__content">
                        <p className="selectedissue__right__chat__message__content__text">{chatMessage.message}</p>
                    </div>
                </div>
            );
        });
    }
    return(
        <div className="selectedissue">
            <div onClick={()=>setSelectedIssueOpen(false)} className="selectedissue__left"></div>
            <div className="selectedissue__right">
                <header className="selectedissue__right__header">
                    <nav className="selectedissue__right__header__nav">
                        <div>
                        <button onClick={()=>setBaseIssues(baseIssues.map(_issue => _issue.id === issue.id ? {..._issue, archived: !issue.archived} : _issue))} className="selectedissue__right__header__nav__btn">
                            {!issue.archived && <i class="fa-regular fa-folder"></i>}
                            {issue.archived && <i class="fa-solid fa-folder"></i>}
                        </button>
                        <div className={`selectedissue__right__header__nav__status selectedissue__right__header__nav__status--${status[issue.status_id].toLowerCase()}`}>
                            {status[issue.status_id]}
                        </div>
                        </div>
                        <i onClick={()=>setSelectedIssueOpen(false)} class="fa-solid fa-xmark selectedissue__right__header__nav__close"></i>
                    </nav>
                    <div className="selectedissue__right__header__wrapper">
                        <h1 className="selectedissue__right__header__wrapper__title">{issue.name}</h1>
                        {!descriptionOpen && <i onClick={()=>setDescriptionOpen(!descriptionOpen)} class="fa-solid fa-chevron-up selectedissue__right__header__wrapper__chevron"></i>}
                        {descriptionOpen && <i onClick={()=>setDescriptionOpen(!descriptionOpen)} class="fa-solid fa-chevron-down selectedissue__right__header__wrapper__chevron"></i>}
                    </div>
                    <div className={`selectedissue__right__header__descriptionwrapper ${descriptionOpen && "selectedissue__right__header__descriptionwrapper--open"}`}>
                        <p className="selectedissue__right__header__descriptionwrapper__description">{issue.description}</p>
                    </div>
                </header>
                <div className="selectedissue__right__chat">
                    <div className="selectedissue__right__chat__message selectedissue__right__chat__message--right selectedissue__right__chat__message--big">
                        <header className="selectedissue__right__chat__message__header">
                            <div className="selectedissue__right__chat__message__header__userwrapper">
                                <figure className="selectedissue__right__chat__message__header__userwrapper__figure">
                                    <img src={PlaceholderPFP} alt="" />
                                </figure>
                                <p className="selectedissue__right__chat__message__header__userwrapper__by">Klantnaam</p>
                            </div>
                            <p className="selectedissue__right__chat__message__header__date">{issue.date}</p>
                        </header>
                        <div className="selectedissue__right__chat__message__content">
                            <h1 className="selectedissue__right__chat__message__content__opened">Klantnaam heeft een item geopend:</h1>
                            <h2 className="selectedissue__right__chat__message__content__title">{issue.title}</h2>
                            <p className="selectedissue__right__chat__message__content__text">{issue.description}</p>
                        </div>
                    </div>
                    {!itemLinesFromApi && <p>Loading...</p>}
                    {itemLinesFromApi && itemLinesFromApi.item_lines && itemLinesFromApi.item_lines.map(chatMessage =>{
                        return(
                            <div className={`selectedissue__right__chat__message selectedissue__right__chat__message${!chatMessage.klant && "--left"} selectedissue__right__chat__message${chatMessage.klant && "--right"}`}>
                                <header className="selectedissue__right__chat__message__header">
                                    <div className="selectedissue__right__chat__message__header__userwrapper">
                                        <figure className="selectedissue__right__chat__message__header__userwrapper__figure">
                                            <img src={PlaceholderPFP} alt="" />
                                        </figure>
                                        <p className="selectedissue__right__chat__message__header__userwrapper__by">{chatMessage.created_name}</p>
                                    </div>
                                    <p className="selectedissue__right__chat__message__header__date">{chatMessage.created_date}</p>
                                </header>
                                <div className="selectedissue__right__chat__message__content">
                                    <p className="selectedissue__right__chat__message__content__text">{chatMessage.message}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <form onSubmit={(ev)=>onSendMessage(ev)} className="selectedissue__right__chatbar">
                    <input  ref={msgRef} placeholder="bericht..." className="selectedissue__right__chatbar__input" type="text" />
                    {/* <button className="selectedissue__right__chatbar__button">
                        <i class="fa-solid fa-link"></i>
                    </button> */}
                    <button className="selectedissue__right__chatbar__button selectedissue__right__chatbar__button--send">
                        <i class="fa-regular fa-paper-plane"></i>
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Selectedissue;