import "./Items.scss";
import { useEffect, useState } from "react";
import calculatedPercentage from "../../helpers/calculatedPercentage";
import { ReactSVG } from "react-svg";
import uploadfilesicon from "../../assets/uploadfilesicon.svg";
import { filterArchived } from "../../helpers/FilterBerichten";
import Issue from "./Issue/Item";
import CreateIssuePopup from "./CreateIssuePopup/CreateItemPopup";
import axios from "axios";
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";


const Items = () => {
    const {token} = useStateContext();
    const [itemsFromApi, setItemsFromApi] = useState();

    const [amountToBeRendered, setAmountToBeRendered] = useState(5);
    const [baseIssues, setBaseIssues] = useState();
    const [issues, setIssues] = useState();
    const [createIssuePopupOpen, setCreateIssuePopupOpen] = useState(false);

    const [statusFilter, setStatusFilter] = useState(false);
    const [archivedFilter, setArchivedFilter] = useState(false);

    const status = ["Ongelezen","In behandeling","Afgerond"]
    
    console.log(token)

    useEffect(()=>{
        if(!issues)return;
        if(amountToBeRendered > issues.length){
            setAmountToBeRendered(issues.length);
        }
    },[amountToBeRendered])

    const fetchItems = () => {
        axios.defaults.headers.Authorization = `Bearer ${token}`
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axiosClient({
            method: 'get',
            url: '/api/v1/Items',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setItemsFromApi(response.data);
          });
    };
    useEffect(() => {
        fetchItems();
    },[]);

    useEffect(() => {
        if(!itemsFromApi)return;
        setBaseIssues(itemsFromApi.items);
        setIssues(itemsFromApi.items);
    }, [itemsFromApi]);

    useEffect(()=>{
        if(!issues)return;
        setAmountToBeRendered(issues.length);
    },[issues])
    
    const loadMoreMessages = () =>{
        setAmountToBeRendered(amountToBeRendered + 5);
    }

    useEffect(()=>{
        let _issues = baseIssues;
        if(archivedFilter){
            _issues = filterArchived(_issues);
        }
        if(statusFilter){
            _issues = _issues.filter(issue => {
                if(status[issue.status_id - 1].toLowerCase() === statusFilter){
                    return issue;
                }
            });
        }
        setIssues(_issues);
    },[archivedFilter, statusFilter, baseIssues])

    const _setStatusFilter = (status) =>{
        if(statusFilter === status){
            setStatusFilter(false);
        }else{
            setStatusFilter(status);
        }
    }

    let issuesToBeRendered = [];
    if(issues){
        issuesToBeRendered = issues.map((issue, index) =>{
            if (archivedFilter && issue.archived) {
                return;
            };
            if(!(index > (amountToBeRendered - 1))){
                return <Issue key={index} issueId={issue.id} baseIssues={baseIssues} setBaseIssues={setBaseIssues}/>;
            }
            return;
        })
    }

    return(
        <>
        {createIssuePopupOpen &&  <CreateIssuePopup fetchItems={fetchItems} baseIssues={baseIssues} setBaseIssues={setBaseIssues} setCreateIssuePopupOpen={setCreateIssuePopupOpen}/>}
        <section className="issues">
            <h1 className="issues__title main-title">Items</h1>
            <header className="issues__header">
                <div className="issues__header__filterwrapper">
                    {!archivedFilter && <>
                    <button onClick={()=>_setStatusFilter("ongelezen")} className={`btn btn--bluewhite ${(statusFilter === "ongelezen") && "btn--bluewhite--selected"}`}><i class="fa-solid fa-eye-slash"></i>Ongelezen</button>
                    <button onClick={()=>_setStatusFilter("in behandeling")} className={`btn btn--bluewhite ${(statusFilter === "in behandeling") && "btn--bluewhite--selected"}`}><i class="fa-solid fa-gears"></i>in behandeling</button>
                    <button onClick={()=>_setStatusFilter("afgerond")} className={`btn btn--bluewhite ${(statusFilter === "afgerond") && "btn--bluewhite--selected"}`}><i class="fa-solid fa-circle-check"></i>Afgerond</button></>
                    }
                </div>
                <div className="issues__header__buttonwrapper">
                    {!archivedFilter && <><button onClick={()=>setArchivedFilter(!archivedFilter)} className="issues__header__buttonwrapper__archive">Bekijk archief</button>
                    <button onClick={()=>setCreateIssuePopupOpen(!createIssuePopupOpen)} className="issues__header__buttonwrapper__create btn btn--greenwhite"><ReactSVG className="document__header__uploadbutton--icon" src={uploadfilesicon} />Nieuw</button></>}
                    {archivedFilter && <button onClick={()=>setArchivedFilter(!archivedFilter)} className="issues__header__buttonwrapper__archive">Terug naar actieve items</button>}
                </div>
            </header>
            <div className="issues__issuewrapper">
                {!issues && <p className="issues__issuewrapper__noissues">Er zijn geen items gevonden</p>}
                {issues && issuesToBeRendered}
            </div>
            {issues && 
                <footer className="issues__footer">
                <p className="issues__footer__amountshown">Toont {amountToBeRendered} van de {issues.length} items</p>
                <div className="issues__footer__amountprogressbar">
                    <div className="issues__footer__amountprogressbar--progress" style={{width: `${calculatedPercentage(amountToBeRendered, issues.length)}%`}}></div>
                </div>
                {!(amountToBeRendered >= issues.length) &&
                    <button onClick={loadMoreMessages} className="issues__footer__loadmorebutton btn">Meer items inladen</button>
                }

                </footer>
            }   
        </section>
        </>
    );
}

export default Items;