export const extensionChecker = (fileExtension) => { 
    const powerpoint = ["ppt", "pptx"];
    const excel = ["xls", "xlsx"];
    const word = ["doc", "docx"];
    const pdf = ["pdf"];
    const image = ["jpg", "jpeg", "png", "gif", "svg"];
    const video = ["mp4", "avi", "mov", "wmv"];
    const audio = ["mp3", "wav", "wma"];
    const text = ["txt"];
    const code = ["html", "css", "js", "php", "py", "java", "c", "cpp", "cs", "vb", "sql"];
    const compressed = ["zip", "rar", "7z", "tar", "gz"];
    const photoshop = ["psd"];
    const illustrator = ["ai"];


    let fileType = "";
        if (powerpoint.includes(fileExtension)){
            fileType = "powerpoint";
        }else if (excel.includes(fileExtension)){
            fileType = "excel";
        }else if (word.includes(fileExtension)){
            fileType = "word";
        }else if (pdf.includes(fileExtension)){
            fileType = "pdf";
        }else if (image.includes(fileExtension)){
            fileType = "image";
        }else if (video.includes(fileExtension)){
            fileType = "video";
        }else if (audio.includes(fileExtension)){
            fileType = "audio";
        }else if (text.includes(fileExtension)){
            fileType = "text";
        }else if (code.includes(fileExtension)){
            fileType = "code";
        }else if (compressed.includes(fileExtension)){
            fileType = "compressed";
        }else if (photoshop.includes(fileExtension)){
            fileType = "photoshop";
        }else if (illustrator.includes(fileExtension)){
            fileType = "illustrator";
        }else{
            fileType = "file";
        }
    return fileType;
}
