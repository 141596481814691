import calculatedPercentage from "../../../helpers/calculatedPercentage";
import "./ProjectVerloop.scss";

const ProjectVerloop = ({project}) =>{
    
    let totalHours = 0;
    let totalCompletedHours = 0;
    project.phases.forEach(phase =>{
        totalHours = totalHours + phase.hours_total;
        if(phase.completedHours > phase.hours_total){                     
            totalCompletedHours = totalCompletedHours + phase.hours_total;
        }else{
            totalCompletedHours = totalCompletedHours + phase.hours_spend;
        }
    })
    const completedPercentage = calculatedPercentage(totalCompletedHours, totalHours)

    const tasksToBeRendered = project.phases.map(phase =>{
        if(phase.name.toLowerCase() === "projectcoördinatie"){return}
        let taskClasses = "progressBar__a";
        if(phase.hours_spend > 0){
            if(phase.status === 2){
                taskClasses = taskClasses.concat(" is-finished");
                if(phase.hours_spend > phase.hours_total){
                    taskClasses = taskClasses.concat(" is-finished--overtime");
                }
            }
            if(phase.status === 3){
                taskClasses = taskClasses.concat(" is-inprogress");
                if(phase.hours_spend > phase.hours_total){
                    taskClasses = taskClasses.concat(" is-inprogress--overtime");
                }
            }
        }
        if(phase.name.toLowerCase() == "projectcoordinatie"){return}
        if(phase.name.toLowerCase() == "vooronderzoek"){return}
        let data_percentage = "";
        if(phase.hours_total){
            data_percentage = calculatedPercentage(phase.hours_spend, phase.hours_total).toFixed() + "%";
        }else{
            data_percentage = phase.hours_spend+"u";
        }
        return <a key={phase.name} className={taskClasses} href={`#${phase.name.toLowerCase().replace(/\s/g, '')}`} data-percentage={data_percentage}>{phase.name}</a>; 
    })

    return(
        <div className="projectVerloop">
            <header className="projectVerloop__header">
                <h3>Projectverloop</h3>
            </header>
            <div className="progressBar">
            <a className="progressBar__a is-finished is-finished--nopercentage" href="#vooronderzoek">Vooronderzoek</a>
                {tasksToBeRendered}
            </div>
            <div className="projectVerloop--datum">
                <p>Start {project.start_date}</p>
                <p>Lancering {project.end_date || "unknown"}</p>
            </div>
        </div>
    );
}

export default ProjectVerloop;