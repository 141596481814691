import {Navigate, Outlet} from "react-router-dom";
import "./GuestLayout.scss";
import LeftLoginPane from "./LeftLoginPane/LeftLoginPane";
import { useStateContext } from "../contexts/ContextProvider";
import { useEffect } from "react";

export default function QuestLayout() {

 

    const { token } = useStateContext();
    if (token) {
        return  <Navigate to="/" />
    }

    return(
        <section className="guestlayout">
            <LeftLoginPane/>
            <div className="login__rightpane">
                <Outlet/>
            </div>
        </section>
    );

}
