import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import CMSButton from "../CMSButton/CMSButton";
import ProgressCircle from "../ProgressCircle/ProgressCircle";
import "./Dashboard.scss";
import axios from "axios";
import { useEffect, useState } from "react";

const Dashboard = () => {
    const [projectsFromApi, setProjectsFromApi] = useState();
    const [project, setProject] = useState();
    const {token} = useStateContext();

    const fetchProjects = () => {
        axiosClient({
            method: 'get',
            url: '/api/v1/Projects',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setProjectsFromApi(response.data);
          });
    };
    useEffect(() => {
        fetchProjects();
    }, []);

    useEffect(() => {
        if(projectsFromApi){
            if(!projectsFromApi.projects) return;
            setProject(projectsFromApi.projects[0]);
        }
    }, [projectsFromApi]);
        

    if(!projectsFromApi || !project){
        return <div>Loading...</div>
    }

    return(
        <section className="section dashboard">
            <ProgressCircle project={project} />
            <CMSButton project={project} />
        </section>
    );
}

export default Dashboard;