import "./CMSButton.scss";

const CMSButton = ({project}) => {
    const cmsUrl = project.url + "/cms";

    return(
        <>
            {project.url && <a className="cmsbutton" href={cmsUrl}>Naar CMS</a>}
            {!project.url && <p onClick={()=>alert("Link is niet ingevuld!")} className="cmsbutton">Naar CMS</p>}
        </>
    );
}

export default CMSButton;