import "./LeftLoginPane.scss";
import WebvalueLogo from "../../assets/webvalue-logo.svg";
import { ReactSVG } from "react-svg";

const LeftLoginPane = () =>{
    return(
        <div className="leftLoginPane">
            
            <div className="textWrapper">
                    <ReactSVG className="leftLoginPane__textWrapper__img" src={WebvalueLogo} alt="Webvalue Logo" />
                    <div className="textWrapper__div">
                    <h2 className="textWrapper__div__h2">Klantportaal</h2>
                    <h1 className="textWrapper__div__h1">Altijd en overal jouw projecten inzichtelijk.</h1>
                </div>
            </div>
        </div>
    );
}

export default LeftLoginPane;
