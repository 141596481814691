import { ReactSVG } from "react-svg"
import { dateDifferenceChecker } from "../../../helpers/dateDifferenceChecker"
import { extensionChecker } from "../../../helpers/extensionChecker"
import { extensionToImage } from "../../../helpers/extensionToImage"

const Document = ({ document }) => {
    
    return (
        <div className="documenten__document">
            <div className="documenten__document__textwrapper">
                <figure className="documenten__document__textwrapper__figure">
                    <p className="documenten__document__textwrapper__figure__uploadedby" >Geupload door {document.created_by || "unknown"}</p>
                    <img className="documenten__header__filterwrapper__uploadedbyfilter__figure--img" src="/src/assets/placeholderPFP.webp" alt=""/>
                </figure>
                <div className="documenten__document__textwrapper__div">
                <p className="documenten__document__textwrapper__div--title">{document.title.replace(`.${document.extension}`, "")}</p>
                <p className="documenten__document__textwrapper__div--date">{dateDifferenceChecker(document.created)}</p>
                </div>
            </div>
            <div className="documenten__document__linkwrapper">
                <ReactSVG src={extensionToImage(extensionChecker(document.extension.toLowerCase()))} className="documenten__document__linkwrapper__img"></ReactSVG>
                <a target="_blank" className="documenten__document__linkwrapper__link" href={document.url}>{document.title}</a>
            </div>
        </div>
    )
}

export default Document