import {Link, Navigate, Outlet, useLocation, useParams} from "react-router-dom";
import {ReactSVG} from "react-svg";
import "./DefaultLayout.scss";
import {useEffect, useState} from "react";
import NotificationsPopup from "./NotificationsPopup/NotificationsPopup.jsx";
import notificationIcon from "../assets/notificationIcon.webp";
import icon from "../assets/webvalue-icon.svg";
import placeholderPFP from "../assets/placeholderPFP.webp";
import navItemsData from "../data/navItemsData.js";
import PopoutNavItem from "./PopoutNavItem.jsx";
import webvaluelogo from "../assets/webvalue-logo.svg";
import { useStateContext } from "../contexts/ContextProvider";

export default function DefaultLayout() {
    const { setToken, token } = useStateContext();
    

    const [navItemsToBeRendered, setNavItemsToBeRendered] = useState();
    const [mobilenavItemsToBeRendered, setMobilenavItemsToBeRendered] = useState();
    const [navItems, setNavItems] = useState([]);
    const [menuOpenClasses, setMenuOpenClasses] = useState("");
    const [menuOpen, setMenuOpen] = useState();
    const [notifOpen, setNotifOpen] = useState();
    const [accountOpen, setAccountOpen] = useState();
    const [accountPopup, setAccountPopup] = useState();
        
    useEffect(()=>{
        setNavItems(navItemsData);
        setMenuOpenClasses("homePage");
        setMenuOpen(false);
        setNotifOpen(false);
        setAccountOpen(false);
        setAccountPopup(<i className="fa-solid fa-chevron-down"></i>)
        
    }, []);

    useEffect(()=>{
        navItemsFunction();
    }, [navItems]);

    const location = useLocation();
    useEffect(() => {
        navItemsFunction();
    }, [location]);

    if (!token) {
        return  <Navigate to="/login" />
    }

    const onNavClick = (text) =>{
        navItems.forEach(navItem => {
            if(navItem.text === text){
                navItem.selected = true
            }else{
                navItem.selected = false
            }
        });
    }

    const navItemsFunction = () =>{
        const tempNavItems = navItems.map((navItem, index)=>{
            let isSelected = false;
            if(("/" + navItem.element) === window.location.pathname){
                isSelected = true;
            }else if((("/" + navItem.element) === "/berichten") && (window.location.pathname === "/items")){
                isSelected = true;
            }
            if(navItem.popout){
                return <PopoutNavItem key={index} navItemsFunction={navItemsFunction} navItem={navItem} isSelected={isSelected}/>
            }
            return(
                <Link key={navItem.text} className="LinkElement" to={"/" + navItem.element} onClick={()=>navItemsFunction()}>
                    <li className={`nav__item ${isSelected && "selected"}`}>
                        <div className="nav__item__wrapper">
                            <ReactSVG className="nav__item__icon" src={navItem.icon} alt="" />
                            <label className="nav__item__title">{navItem.text}</label>
                        </div>
                    </li>
                </Link>
            );
        })
        const tempMobileNavItems = navItems.map(navItem =>{
            let isSelected = false;
            if(("/" + navItem.element) === window.location.pathname){
                isSelected = true;
            }else if((("/" + navItem.element) === "/berichten") && (window.location.pathname === "/items")){
                isSelected = true;
            }
            
            return(
                <Link key={navItem.text} className="LinkElement" to={"/" + navItem.element}>
                    <li className={`mobilenav__ul__li ${isSelected && "mobilenav__ul__li--selected"}`}>
                        <ReactSVG className="mobilenav__ul__li__icon" src={navItem.icon} alt="" />
                        <label className="mobilenav__ul__li__title">{navItem.text}</label>
                    </li>
                </Link>
            );
        })
        setNavItemsToBeRendered(tempNavItems);
        setMobilenavItemsToBeRendered(tempMobileNavItems);
    }

    const onMenuClick = () =>{

        if(!menuOpen){
            setMenuOpenClasses("homePage menuOpen");
        }else{
            setMenuOpenClasses("homePage");
        }

        setMenuOpen(!menuOpen);
    }

    const onAccountClick = () =>{
        if(!accountOpen){
            setAccountPopup(
                <>
                <i className="fa-solid fa-chevron-up"></i>
                <div className="account__popup">
                    <h2 className="account__popup__user">Ingelogd als </h2>
                    <h2 className="account__popup__user">{"user.name"}</h2>
                    <button onClick={onLogout} className="account__popup__btn">Logout</button>
                </div>
                </>
            )
        }else{
            setAccountPopup(<i className="fa-solid fa-chevron-down"></i>)
        }
        setAccountOpen(!accountOpen)
    }

    const onLogout = (ev) =>{
        ev.preventDefault()
        setToken(null)
    }

    return(
    <div className={menuOpenClasses}>
        <NotificationsPopup notifOpen={notifOpen} setNotifOpen={setNotifOpen} onNavClick={onNavClick}/>
        <div className="logo">
            <ReactSVG className="logo__img" src={webvaluelogo}/>
        </div>
        <header className="header">
            <div className="logo logo--display">
                <ReactSVG className="logo--display__img" src={icon} alt="webvalue icon" />
            </div>
            <div>
            <div onClick={() => setNotifOpen(!notifOpen)} className="notifications"><img src={notificationIcon} alt="notification icon" /><div></div></div>
                <div onClick={onAccountClick} className="account">
                    <figure>
                        <img src={placeholderPFP} alt="" />
                    </figure>
                    <p>Mijn account</p>
                    {accountOpen && <i className="fa-solid fa-chevron-up"></i>}
                    {!accountOpen && <i className="fa-solid fa-chevron-down"></i>}
                    <div className={`account__popup ${accountOpen && "account__popup--isopen"}`}>
                        <h2 className="account__popup__user">Ingelogd als </h2>
                        <h2 className="account__popup__user">{"user.name"}</h2>
                        <button onClick={onLogout} className="account__popup__btn">Logout</button>
                    </div>
                </div>
            </div>
        </header>
        <nav className="nav">
            <ul className="nav__list">
                {navItemsToBeRendered}
            </ul>
        </nav>
        <div>
            {(window.location.pathname === "/berichten" || window.location.pathname === "/items") &&
            <div className="berichtissueswitch">
                <div className="berichtissueswitch__wrapper">
                    <div className="berichtissueswitch__wrapper__btnwrapper">
                        <Link to="/berichten" className="berichtissueswitch__wrapper__btnwrapper__btn">Updates</Link>
                        <Link to="/items" className="berichtissueswitch__wrapper__btnwrapper__btn">Issues</Link>
                        <div className={`berichtissueswitch__wrapper__btnwrapper__selected ${window.location.pathname === "/items" && "berichtissueswitch__wrapper__btnwrapper__selected--right"}`}></div>
                    </div>
                </div>
            </div>}
            <Outlet />
        </div>
        
        <div className="navfiller do_not_remove_thanks"></div>
        <nav className="mobilenav">
            <ul className="mobilenav__ul">
                {mobilenavItemsToBeRendered}
            </ul>
        </nav>
    </div>
    );
}
