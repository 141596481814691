import { useEffect, useState } from "react";
import ProjectDetailsTitle from "../../ProjectDetailsTitle/ProjectDetailsTitle";
import AkkoordNodig from "../Components/AkkoorNodig/AkkoordNodig";
import GoedgekeurdDoor from "../Components/GoedgekeurdDoor/GoedgekeurdDoor";
import UrenBalk from "../Components/UrenBalk/UrenBalk";
import "./ProjectDetail.scss";
import databaseIntToBool from "../../../../helpers/databaseIntToBool";
import axios from 'axios';
import { useStateContext } from "../../../../contexts/ContextProvider";
import axiosClient from "../../../../axios-client";

const ProjectDetail =({id, phase}) =>{
    const [projectDetailOpen, setProjectDetailOpen] = useState(false);
    const [rules, setRules] = useState();
    const {token} = useStateContext();

    const fetchRules = (id) => {
        axiosClient({
            method: 'get',
            url: `/api/v1/ProjectLines/${id}`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setRules(response.data);
          });
        //return axios.get(url).then((res) => console.log(res));
    };
    useEffect(() => {
        fetchRules(id);
    }, []);

    useEffect(() => {
        if(rules){
            let noRules = true
            if(phase.lines.length > 0){
                setProjectDetailOpen(true)
            }
        }
    }, [rules])
    
    const akkoordNodigArray = ["vooronderzoek", "design", "meerwerk"];

 if(!rules){
    return <div>Loading...</div>;
    }else if(rules){
    return(
        <div id={phase.name.toLowerCase().replace(/\s/g, '')} className="projectDetails__detail">
            <div className={`projectDetailsTitle ${!projectDetailOpen && "projectDetailsTitle--isclosed"}`}>
                <h3 className="projectDetailsTitle__title">{phase.name}</h3>
                <i onClick={()=>setProjectDetailOpen(!projectDetailOpen)} class="fa-solid fa-minus"></i>
                <i onClick={()=>setProjectDetailOpen(!projectDetailOpen)} class="fa-solid fa-plus"></i>
            </div>
            <div className={`projectDetails__detail__akkoordwrapper projectDetails__detail__info ${!projectDetailOpen && "projectDetails__detail__info--isclosed"}`}>
                {phase.lines.map((line, index) => {
                        return(
                            <div key={index} className="projectDetails__detail__info__wrapper">
                                <UrenBalk text={line.title} date="22-22-2222" hours={line.time_available} completedHours={line.time_used}/>
                                {akkoordNodigArray.includes(phase.name.toLowerCase()) && <>
                                {line.approved_name && line.signature && <GoedgekeurdDoor name={line.approved_name} date={line.approved_date} />}
                                {!line.signature && (line.status_id == 1) && <AkkoordNodig />}
                                </>}
                            </div>
                        );
                    
                })}
                {/*
                    {item.lines.map((line, index) => {
                        if(line.agreed !== undefined){
                            return(
                            <div className="projectDetails__detail__info__wrapper">
                                <UrenBalk text={line.name} date={line.date_added} hours={line.hours_total} completedHours={line.hours_spend}/>
                                {databaseIntToBool(line.agreed) && <GoedgekeurdDoor name={line.agree_by} date={line.date_added} />}
                                {!databaseIntToBool(line.agreed) && <AkkoordNodig />}
                            </div>
                        )} else{
                            return(
                                <div className="projectDetails__detail__info__wrapper">
                                    <UrenBalk text={line.name} date={line.date_added} hours={line.hours_total} completedHours={line.hours_spend}/>
                                </div>
                            )
                        }
                    })}
                */}
                
            </div>
        </div>
    )}
}

export default ProjectDetail;