import {createBrowserRouter, Navigate} from "react-router-dom";
import Login from "./views/Login.jsx";
import Admin from "./views/Admin.jsx";
import NotFound from "./views/NotFound.jsx";
import DefaultLayout from "./components/DefaultLayout.jsx";
import GuestLayout from "./components/GuestLayout.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import AdminLayout from "./components/AdminLayout.jsx"
import Support from "./components/Support/Support.jsx";
import Documenten from "./components/Documenten/Documenten.jsx";
import Projecten from "./components/Projecten/Projecten.jsx";
import OfferteOndertekenen from "./components/OfferteOndertekenen/OfferteOndertekenen.jsx";
import Items from "./components/Items/Items.jsx";
import Berichten from "./components/Berichten/Berichten.jsx";
import Overview from "./views/Overview.jsx";

const router = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                path: '/',
                element: <Navigate to="/projecten" />
            },
            {
                path: '/projecten',
                element: <Projecten />
            },
            {
                path: '/dashboard',
                element: <Dashboard />
            },
            {
                path: '/berichten',
                element: <Berichten />
            },
            {
                path: '/items',
                element: <Items />
            },
            {
                path: '/support',
                element: <Support />
            },
            {
                path: '/documenten',
                element: <Documenten />
            },
            {
                path: '/offerte',
                element: <OfferteOndertekenen />
            },
        ]
    },
    {
        path: '/',
        element: <AdminLayout />,
        children: [
            {
                path: '/admin',
                element: <Admin />
            },
        ]
    },
    {
        path: '/',
        element: <GuestLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/overview',
                element: <Overview />
            },
        ]
    },

    {
        path: '/',
        element: <NotFound />
    }
])

export default router;
