import "./Overview.scss";

import {useEffect, useState} from "react";
import axios from "axios";

import placeholderPFP from "../assets/placeholderPFP.webp";
import Artwork from "../assets/Artwork.webp";
import { Navigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import axiosClient from "../axios-client";

const Overview = () => {
    const [projectsFromApi, setProjectsFromApi] = useState();
    const {token} = useStateContext();

    const fetchProjects = () => {
        axiosClient({
            method: 'get',
            url: '/api/v1/Projects',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            console.log(response.data);
            setProjectsFromApi(response.data);
          });
    };
    useEffect(() => {
        fetchProjects();
    }, []);

    if(!projectsFromApi){
        return <div>Loading...</div>;
    }
    if(projectsFromApi.projects.length < 2){
        return <Navigate to="/projecten"/>;
    }

    return(
        <div className="overview">
            <header className="overview__header">
                <h2 className="overview__header__title">Uw projecten</h2>
                <div className="overview__header__user">
                    {projectsFromApi && <p className="overview__header__user__name">{projectsFromApi.name}</p>}
                    <figure className="overview__header__user__figure">
                        <img className="overview__header__user__figure__img" src={placeholderPFP} alt="" />
                    </figure>
                </div>
            </header>
            <div className="overview__projects">
                {projectsFromApi && projectsFromApi.projects.map((project, index) => (
                    <a href="/" className="overview__projects__project">
                        <div className="overview__projects__project__banner">
                            <div className="overview__projects__project__banner__dropdown">
                                <button className="overview__projects__project__banner__dropdown__link">View project</button>
                            </div>
                            <figure className="overview__projects__project__banner__figure">
                                <img className="overview__projects__project__banner__figure__img" src={Artwork} alt="banner" />
                            </figure>
                        </div>
                        <div className="overview__projects__project__info">
                        <p className="overview__projects__project__info__date">{project.start_date}</p>
                        <h3 className="overview__projects__project__info__title">{project.name}</h3>
                        </div>
                    </a>
                ))}

            </div>
        </div>
    );
}

export default Overview;