import { useEffect, useState } from "react";
import SignaturePopup from "../../components/SignaturePopup/SignaturePopup";
import "./OfferteOndertekenen.scss";
import { Link } from "react-router-dom";
import voorblad from "../../assets/Voorblad.png";
import downloadicon from "../../assets/download-icon.png";



import GoedgekeurdDoor from "../Projecten/ProjectDetails/Components/GoedgekeurdDoor/GoedgekeurdDoor.jsx";

const OfferteOndertekenen = () =>{
    const [signaturePopupOpen, setSignaturePopupOpen] = useState();
    const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
    const [offerteOndertekend, setOfferteOndertekend] = useState()



    useEffect(()=>{
        setSignaturePopupOpen(false);
    },[])

    const switchSignaturePopupOpen = () =>{

        setSignaturePopupOpen(!signaturePopupOpen);
        // document.getElementById('iframe').onload = function()
        // {
        //     var svg = document.getElementById('iframe').contentWindow;
        //     svg.setSignature(user.signature);
        // }
    }

    

    return(
        <div className="section offerteondertekenen">
            <SignaturePopup signaturePopupOpen={signaturePopupOpen} switchSignaturePopupOpen={switchSignaturePopupOpen}/>
            <header className="offerteondertekenen__header">
                <Link to="/"><button className="btn offerteondertekenen__header__backbutton">Terug naar project</button></Link>
                <h1 className="offerteondertekenen__header__title">Maatwerk website voor Klantnaam</h1>
            </header>

            <div className="offerteondertekenen__wrapper">
                <section className="offerteondertekenen__pdfsection offerteondertekenen__section">
                    <header className="offerteondertekenen__section__header">
                        <h2 className="offerteondertekenen__section__header__title">Download de PDF van uw kostenindicatie</h2>
                        <a className="link offerteondertekenen__section__header__link" href="offerte.pdf" download="offerte.pdf" onClick={undefined}>Download je kostenindicatie</a>
                    </header>
                    <div className="offerteondertekenen__pdfsection__pdf">
                        <figure className="offerteondertekenen__pdfsection__pdf__figure">
                            <img className="offerteondertekenen__pdfsection__pdf__figure__img" src={voorblad} alt="" />
                            <a href="offerte.pdf" download="offerte.pdf" onClick={undefined} className="offerteondertekenen__pdfsection__pdf__figure__download">
                                <img className="offerteondertekenen__pdfsection__pdf__figure__download__icon" src={downloadicon} alt="download icon" />
                            </a>
                        </figure>
                    </div>
                </section>
                <section className="offerteondertekenen__sidebar offerteondertekenen__section">
                    <header className="offerteondertekenen__sidebar__header">
                        {!offerteOndertekend &&
                            <h2 className="offerteondertekenen__sidebar__header__title">Kostenindicatie goedkeuren</h2>}
                        {offerteOndertekend &&
                            <>
                            <h2 className="offerteondertekenen__sidebar__header__title">Kostenindicatie is goedgekeurd</h2>
                            <i className="fa-solid fa-check offerteondertekenen__sidebar__header__checkmark"></i></>}
                    </header>
                    {!offerteOndertekend &&
                    <div className="offerteondertekenen__sidebar__inputwrapper">
                        <label className="offerteondertekenen__sidebar__inputwrapper__label" htmlFor="firstlastname">Jouw naam</label>
                        <input className="offerteondertekenen__sidebar__inputwrapper__input" type="text" name="firstlastname" id="firstlastname" placeholder="Uw voor en achternaam"/>
                        <button onClick={switchSignaturePopupOpen} className="btn offerteondertekenen__sidebar__inputwrapper__button">Handtekening plaatsen</button>
                    </div>}
                    {offerteOndertekend &&
                    <div className="offerteondertekenen__sidebar__inputwrapper">
                        <GoedgekeurdDoor name="Naam" date="22-01-2020"/>
                    </div>}
                    <p className="offerteondertekenen__sidebar__date">Offerte aangemaakt op 01 januari 2020</p>
                </section>
            </div>
        </div>
    )
}

export default OfferteOndertekenen;

//https://pspdfkit.com/blog/2021/how-to-build-a-reactjs-pdf-viewer-with-react-pdf/
