export const dateDifferenceChecker = (dateToCheck) =>{
    const date1 = new Date(dateToCheck.replace("-","/").replace("-","/"));
    const date2 = Date.now();

// To calculate the time difference of two dates
    const Difference_In_Time = date2 - date1.getTime();

// To calculate the no. of days between two dates
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days > 31){
        return dateToCheck;
    }else if (Difference_In_Days > 14){
        return "Deze maand";
    }else if (Difference_In_Days > 7){
        return "Vorige week";
    }else if (Difference_In_Days > 2){
        return "Deze week";
    }else if (Difference_In_Days > 1){
        return "Gisteren";
    }else{
        return "Vandaag";
    }
}
