// Icons
import dashboardIcon from "../assets/dashboardIcon.svg"
import projectsIcon from "../assets/projectsIcon.svg"
import documentsIcon from "../assets/documentsIcon.svg"
import letterIcon from "../assets/letterIcon.svg"

const navItems = [
    {
        text: "Dashboard",
        selected: false,
        icon: dashboardIcon,
        element: "dashboard",
    },
    {
        text: "Projecten",
        selected: true,
        icon: projectsIcon,
        element: "projecten",
    },
    {
        text: "Berichten",
        selected: false,
        icon: letterIcon,
        element: "berichten",
        popout: true,
        items: ["berichten", "issues"],
    },
    {
        text: "Documenten",
        selected: false,
        icon: documentsIcon,
        element: "documenten",
    },
]

export default navItems;
