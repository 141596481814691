import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import "./DefaultLayout.scss";
import axios from "axios";
import capNumberAt from "../helpers/capNumberAt";

const PopoutNavItem = ({navItem, isSelected, navItemsFunction}) => {
    const [popoutOpen, setPopoutOpen] = useState(false);
    const [updatesFromApi, setUpdatesFromApi] = useState();
    const [itemsFromApi, setItemsFromApi] = useState();
    const [openItems, setOpenItems] = useState([]);
    const [unreadUpdates, setUnreadUpdates] = useState([]);
    let notifsToBeRendered = ""; // das die nummer achter berichten navitem


    const fetchUpdates = () => {
        axios({
            method: 'get',
            url: '/api/v1/Updates',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: 'TempTokenWebvalue3',
                submit: 'submit'
            }
        }).then(function (response) {
            setUpdatesFromApi(response.data);
          });
    };
    const fetchItems = () => {
        axios({
            method: 'get',
            url: '/api/v1/Items',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: 'TempTokenWebvalue3',
                submit: 'submit'
            }
        }).then(function (response) {
            setItemsFromApi(response.data);
          });
    };
    useEffect(() => {
        fetchItems();
    },[]);
    useEffect(() => {
        fetchUpdates();
    }, []);
    useEffect(()=>{
        if(!itemsFromApi)return;
        if(!itemsFromApi.items)return;
        setOpenItems(itemsFromApi.items.filter(update => itemsFromApi.status !== "3"));
    },[itemsFromApi])
    useEffect(()=>{
        if(!updatesFromApi)return;
        if(!updatesFromApi.updates)return;
        setUnreadUpdates(updatesFromApi.updates.filter(update => !update.read));
    },[updatesFromApi])

    let berichtenAmount = 0;
    if(updatesFromApi || itemsFromApi){
        berichtenAmount = unreadUpdates.length + openItems.length
    }

    return (
        <>
        <Link to="/berichten" key={navItem.text} className={`nav__item ${isSelected && "selected"}`} onClick={()=>navItemsFunction()}>
            <div className="nav__item__wrapper">
                <ReactSVG className="nav__item__icon" src={navItem.icon} alt="" />
                <label className="nav__item__title">{navItem.text}</label>
                
                {<p className="notifthingy">{capNumberAt(berichtenAmount, 9)}</p>}
            </div>
        </Link>
        <div className={`nav__item__popout ${(useLocation().pathname === "/berichten") && "nav__item__popout--open"} ${(useLocation().pathname === "/items") && "nav__item__popout--open"}`}>
            <Link className="LinkElement nav__item__popout__item" to="/berichten">
                <p className={`nav__item__popout__item__title ${(useLocation().pathname === "/berichten") && "nav__item__popout__item__title--selected"}`}>Updates</p>
                {(unreadUpdates.length > 0) && <p className="nav__item__popout__item__amount nav__item__popout__item__amountblue">Ongelezen {unreadUpdates && capNumberAt(unreadUpdates.length, 99)}</p>}
            </Link>
            <Link className="LinkElement nav__item__popout__item" to="/items">
                <p className={`nav__item__popout__item__title ${(useLocation().pathname === "/items") && "nav__item__popout__item__title--selected"}`}>Items</p>
                {(openItems.length > 0) && <p className="nav__item__popout__item__amount nav__item__popout__item__amountred">Openstaand {openItems && capNumberAt(openItems.length, 99)}</p>}
            </Link>
        </div>
        </>
    );
}
    
export default PopoutNavItem;