import { useEffect, useState } from "react";
import ProjectDetailsTitle from "../../ProjectDetailsTitle/ProjectDetailsTitle";
import AkkoordNodig from "../Components/AkkoorNodig/AkkoordNodig";
import GoedgekeurdDoor from "../Components/GoedgekeurdDoor/GoedgekeurdDoor";
import UrenBalk from "../Components/UrenBalk/UrenBalk";
import "./Meerwerk.scss";
import databaseIntToBool from "../../../../helpers/databaseIntToBool";

const Meerwerk = ({additional_work}) =>{
    const [projectDetailsClasses, setProjectDetailsClasses] = useState("");

    useEffect(() =>{
        setProjectDetailsClasses("projectDetails__detail__info projectDetails__detail__info--meerwerk");
    },[])


    return(
        <div id="meerwerk" className="projectDetails__detail">
            <ProjectDetailsTitle detailName="meerwerk" setProjectDetailsClasses={setProjectDetailsClasses} title="Meerwerk"/>
            <div className={projectDetailsClasses}>
                {additional_work.map((item, index) => {
                    return(<div>
                        <h2>{item.name}</h2>
                        <div className={`projectDetails__detail__akkoordwrapper meerwerk__${item.name.toLowerCase()}wrapper`}>
                            {item.lines.map((line, index) => {
                                return(<>
                                    <UrenBalk text={line.name} date={line.date_added} hours={line.hours_total}/>
                                    {databaseIntToBool(line.agreed) && <GoedgekeurdDoor name={line.agree_by} date={line.agree_date}/>}
                                    {!databaseIntToBool(line.agreed) && <AkkoordNodig/>}
                                </>)
                            })
                        }
                        </div>
                    </div>)
                    })
                }
                {/* <div>
                    <h2>Design</h2>
                    <div className="projectDetails__detail__akkoordwrapper meerwerk__designwrapper">
                        <UrenBalk text="Elementen verwijderd op de homepage" date="22-01-2020" hours={2}/>
                        <GoedgekeurdDoor name="Naam" date="22-01-2020"/>
                        <UrenBalk text="Nieuwe strook met nieuwsoverzicht toevoegen" date="22-01-2020" hours={1}/>
                        <AkkoordNodig />
                        <UrenBalk text="Toevoeging extra template voor registreren" date="22-01-2020" hours={1}/>
                        <AkkoordNodig />
                    </div>
                </div>
                <div>
                    <h2>Techniek</h2>
                    <div className="projectDetails__detail__akkoordwrapper meerwerk__techniekwrapper">
                        <UrenBalk text="Betaling via Mollie mogelijk maken" date="22-01-2020" hours={3}/>
                        <AkkoordNodig />
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default Meerwerk;