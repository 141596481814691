import { useEffect, useState } from "react";
import placeholderPFP from "../../../assets/placeholderPFP.webp";
import { dateDifferenceChecker } from "../../../helpers/dateDifferenceChecker";
import "./Item.scss";
import Selectedissue from "../../SelectedIssue/SelectedIssue";
import axios from "axios";
import { useStateContext } from "../../../contexts/ContextProvider";
import axiosClient from "../../../axios-client";

const Item = ({ issueId, baseIssues, setBaseIssues}) => {
    const {token} = useStateContext();
    const [itemFromApi, setItemFromApi] = useState();

    const [selectedIssueOpen, setSelectedIssueOpen] = useState(false);

    const fetchItems = (id) => {
        axiosClient({
            method: 'get',
            url: `/api/v1/Items/${id}`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setItemFromApi(response.data);
          });
    };
    useEffect(() => {
        fetchItems(issueId);
    },[]);

    if(!itemFromApi)return;
    const issue = itemFromApi;

    const status = ["Ongelezen","in behandeling","Afgerond"]

    // const issueUsersToBeRendered = issue.users.map((user, index) =>{
    //     return(
    //         <figure className="issues__issue__btnwrapper__userwrapper__figure">  
    //             <p className="issues__issue__btnwrapper__userwrapper__figure__by" >{user || "unknown"}</p>
    //             <img className="issues__issue__btnwrapper__userwrapper__figure--img" src={placeholderPFP} alt=""/>
    //         </figure>
    //     );
    // })
    return(
        <>
        {selectedIssueOpen &&  <Selectedissue setSelectedIssueOpen={setSelectedIssueOpen} baseIssues={baseIssues} setBaseIssues={setBaseIssues} issue={issue} issueId={issueId}/>}
        <div className={`issues__issue ${selectedIssueOpen && "body-overflow-hidden"}`}>
            <div className="issues__issue__textwrapper">
                <div onClick={()=>setSelectedIssueOpen(!selectedIssueOpen)} className="issues__issue__textwrapper__div">
                    <p className="issues__issue__textwrapper__div--title">{issueId}{issue.name}</p>
                    <p className="issues__issue__textwrapper__div--date">{dateDifferenceChecker(baseIssues.filter(issue => issue.id == issueId)[0].created_date)}</p>
                </div>
            </div>
            <div className="issues__issue__btnwrapper">
                {/* <div className="issues__issue__btnwrapper__userwrapper">
                    <i class="fa-solid fa-chevron-up issues__issue__btnwrapper__userwrapper__chevron"></i>
                    {issueUsersToBeRendered}
                </div> */}
                
                <div className={`issues__issue__btnwrapper__status issues__issue__btnwrapper__status--${status[issue.status_id - 1].toLowerCase()}`}>
                    {status[issue.status_id - 1]}
                </div>
                <button onClick={()=>setBaseIssues(baseIssues.map(_issue => _issue.id === issue.id ? {..._issue, archived: !issue.archived} : _issue))} className="issues__issue__btnwrapper__btn">
                    {!issue.archived && <i class="fa-regular fa-folder"></i>}
                    {issue.archived && <i class="fa-solid fa-folder"></i>}
                </button>
                <button onClick={()=>setSelectedIssueOpen(!selectedIssueOpen)} className="issues__issue__btnwrapper__btn">
                    <i class="fa-solid fa-info"></i>
                </button>
            </div>
        </div>
        </>
    );
}

export default Item;