import "./Login.scss";
import Vinkje from "../assets/Vinkje.svg";
import {ReactSVG} from "react-svg";
import {useState, useEffect, useRef} from "react";
import ForgotPasswordPopup from "../components/ForgotPasswordPopup/ForgotPasswordPopup.jsx";
import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import axiosClient from "../axios-client";
export default function Login() {
    const {setUser, setToken} = useStateContext()
    const emailRef = useRef();
    const pwdRef = useRef();
    const [popupOpen, setPopupOpen] = useState();
    const [errors, setErrors] = useState(null);

    useEffect(()=>{
        setPopupOpen(false);
    },[])

    const changePopupOpen = () =>{
        setPopupOpen(!popupOpen);
    }

    const onSubmit = (ev) =>{
        ev.preventDefault()
       
        const payload =
            {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                email: emailRef.current.value,
                password: pwdRef.current.value,
                submit: 'submit'
            }

        setErrors(null);
        axiosClient({
            method: 'post',
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                submit: 'submit'
            },
            url: `/api/v1/Login`,
            data: payload
        }).then(({data})=>{
                if(data.token) {
                    localStorage.setItem('ACCESS_TOKEN', data.token);
                }else{
                    localStorage.removeItem('ACCESS_TOKEN');
                }
                console.log(localStorage.getItem('ACCESS_TOKEN'));
                setToken(data.token);
            })
            .catch(err =>{
                console.log(err);
                const response = err.response;
                if(response && response.status === 422){
                    setErrors(response.data.errors)
                }
            })
    }

    return (
        <div className="loginform">
            <ForgotPasswordPopup popupOpen={popupOpen} changePopupOpen={changePopupOpen}/>
            <h2 className="loginform__h2">Inloggen</h2>
            {errors && <div className="loginform__errors">
                {Object.keys(errors).map(key => (
                    <p className="loginform__error" key={key}>{errors[key][0]}</p>
                ))
            }
            </div>}
            <form className="loginform__form" onSubmit={onSubmit}>
                <div className="loginform__form__inputWrapper">
                    <label className="loginform__form__inputWrapper__label" htmlFor="username">Gebruikersnaam</label>
                    <input ref={emailRef} defaultValue={"hr@dormac.nl"} className="loginform__form__inputWrapper__input" type="text" name="username" id="username" placeholder="Uw gebruikersnaam"/>
                </div>
                <div className="loginform__form__inputWrapper">
                    <label className="loginform__form__inputWrapper__label" htmlFor="password">Wachtwoord</label>
                    <input ref={pwdRef} defaultValue={"Test1234!"} className="loginform__form__inputWrapper__input" type="password" name="password" id="password" placeholder="Uw wachtwoord"/>
                </div>
                <div className="loginform__form__loginOptionsWrapper">
                        <input className="loginOptionsWrapper__inputWrapper__input loginOptionsWrapper__inputWrapper__input--staysignedin" type="checkbox" name="staysignedin" id="staysignedin"/>
                        <label className="loginOptionsWrapper__inputWrapper__label loginOptionsWrapper__inputWrapper__label--staysignedin" htmlFor="staysignedin">
                            Ingelogd blijven
                        
                            <span className="loginOptionsWrapper__inputWrapper__span">
                                <ReactSVG src={Vinkje} alt="vinkje"  className="loginOptionsWrapper__inputWrapper__span--icon"/>
                            </span>
                            
                        </label>
                    
                    <a className="loginOptionsWrapper__forgotpwd" onClick={changePopupOpen}>Wachtwoord vergeten?</a>
                </div>
                <button className="loginform__form__loginButton btn" onClick={onSubmit}>Inloggen</button>
            </form>
        </div>
    )
}
