import "./NotificationsPopup.scss";
import placeholderPFP from "../../assets/placeholderPFP.webp"
import {Navigate} from "react-router-dom";
import notifsData from "../../data/notifsData.js";


const NotificationsPopup = ({ setNotifOpen, notifOpen, onNavClick}) =>{

    const notificationsToBeRendered = notifsData.map(notification =>{
        return(
            <div key={notification.id} className="notification">
                    <img className="notification__img" src={notification.pfp || placeholderPFP} alt="profile picture" />
                    <div className="notification__textwrapper">
                        <h2  className="notification__textwrapper__title"><b className=".notification__textwrapper__title--bold">{notification.name}</b> {notification.task}</h2>
                        <p className="notification__textwrapper__time">{notification.time}</p>
                    </div>
                </div>
        );
    })

    const onButtonClick = () =>{
        setNotifOpen(!notifOpen)
        window.location.replace("/berichten")

    }

    return(
        <div className={`notificationsPopup ${notifOpen && "is-open"}`}>
            <h1 className="notificationsPopup__title">Meldingen</h1>
            <div className="notificationWrapper">
                {notificationsToBeRendered}
            </div>
            <div className="notificationsPopup__buttonwrapper">
                <button className="notificationsPopup__buttonwrapper__button" onClick={onButtonClick}>Bekijk alle meldingen</button>
            </div>
        </div>
    );
}

export default NotificationsPopup;
