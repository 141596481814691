export const filterArchived = (toBeSearchedArray) =>{
    if(!toBeSearchedArray)return;
    return toBeSearchedArray.filter(item =>{
        if (item.archived === true){
            return item;
        }
    })
}

export const filterImportant = (toBeSearchedArray) =>{
    if(!toBeSearchedArray)return;
    return toBeSearchedArray.filter(item =>{
        if (item.important === true){
            return item;
        }
    })
}

export const filterUnread = (toBeSearchedArray) =>{
    if(!toBeSearchedArray)return;
    return toBeSearchedArray.filter(item =>{
        if (!item.read){
            return item;
        }
    })
}