import "./Projecten.scss";
import ProjectVerloop from "./ProjectVerloop/ProjectVerloop";
import ProjectCoordinatie from "./ProjectCoordinatie/ProjectCoordinatie";
import Meerwerk from "./ProjectDetails/Meerwerk/Meerwerk";
import {Link, Navigate} from "react-router-dom";
import TotaalMeerwerk from "./TotaalMeerwerk/TotaalMeerwerk";
import ProjectDetail from "./ProjectDetails/ProjectDetail/ProjectDetail";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../axios-client";



const Projecten = () => {
    const {token} = useStateContext();
    const [projectsFromApi, setProjectsFromApi] = useState();
    const [projectFromApi, setProjectFromApi] = useState();

    console.log(localStorage.getItem('ACCESS_TOKEN'));
    console.log(token)


    const fetchProjects = () => {
        axios.defaults.headers.post['Access-Control-Allow-Headers'] ='Content-Type, Authorization, X-Requested-With';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'DELETE, POST, GET, OPTIONS';
        axiosClient({
            method: 'get',
            url: '/api/v1/Projects',
            withCredentials: false,
            crossdomain: true,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setProjectsFromApi(response.data);
          });
        //return axios.get(url).then((res) => console.log(res));
    };
    const fetchProject = (id) => {
        axiosClient({
            method: 'get',
            url: `/api/v1/Projects/${id}`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            }
        }).then(function (response) {
            setProjectFromApi(response.data);
          });
        //return axios.get(url).then((res) => console.log(res));
    };
    useEffect(() => {
        fetchProjects();
    }, []);
    useEffect(() => {
        if(!projectsFromApi)return;
        if(!projectsFromApi.projects)return;
        fetchProject(projectsFromApi.projects[0].pm_project_id);
    }, [projectsFromApi]);
    

    const showKostenIndicatie =()=>{
        return <Navigate to="/offerte"/>;
    }

    if(!projectsFromApi || !projectFromApi){
        return <div>Loading...</div>;
    }else if(projectsFromApi && projectFromApi){
    return(
        <div className="section projecten">
            <header className="projecten__header">
                <h2 className="projecten__header__h2 main-title">{projectFromApi.project_name}</h2>
                <div className="titleLinksWrapper">
                    <button className="titleLinksWrapper__button btn btn--greywhite">Bekijk website ontwerp</button>
                    <a href="" className="titleLinksWrapper__button btn btn--greywhite">Bekijk testomgeving</a>
                    <Link to="/offerte" className="titleLinksWrapper__button btn btn--greywhite">Bekijk kostenindicatie</Link>
                </div>
            </header>
            <section className="status">
                <ProjectVerloop project={projectFromApi}/>
                <ProjectCoordinatie project_coordination={projectFromApi.project_coordination}/>
                <TotaalMeerwerk additional_work={projectsFromApi.projects[0].additional_work}/>
            </section>
            <section className="projectDetails">
                <h2 className="projectDetails--h2">Project details</h2>
                {projectFromApi.phases.map((phase, index) => {
                    if(phase.name.toLowerCase() === "projectcoördinatie"){return}
                    return <ProjectDetail key={index} id={projectsFromApi.projects[0].pm_project_id} phase={phase}/>;
                })}
                {projectsFromApi.projects[0].additional_work && <Meerwerk additional_work={projectsFromApi.projects[0].additional_work}/>}
                
            </section>
        </div>
    )}
}

export default Projecten; 
