import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./Admin.scss";

export default function Admin() {
    

    return (
        <div className="users">
            <header className="users__header">
                <h1 className="users__header__title">Admin paneel</h1>
                <a className="users__header__newuserbutton" >???</a>
            </header>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>???</th>
                            <th>???</th>
                            <th>???</th>
                            <th>???</th>
                            <th>???</th>
                        </tr>
                    </thead>
                    
                </table>
            </div>
        </div>
    )
}
