import { extensionChecker } from "./extensionChecker";

export const filterDocumentsByTitle = (toBeSearchedArray, toBeSearchedTitle) =>{
    return toBeSearchedArray.filter(document => {
        if(document.title.toUpperCase().indexOf(toBeSearchedTitle.toUpperCase()) !== -1 ){
            return document;
        }
    });
}

export const filterDocumentsByType = (toBeSearchedArray, documentTypeToBeFiltered) =>{
    return toBeSearchedArray.filter(document =>{
        if (extensionChecker(document.extension) === documentTypeToBeFiltered){
            return document;
        }
    })
}

export const filterDocumentsByUser = (toBeSearchedArray, userToBeFiltered) =>{
    return toBeSearchedArray.filter(document =>{
        if (document.user_id === userToBeFiltered){
            return document;
        }
    })
}
