import "./TotaalMeerwerk.scss";

const TotaalMeerwerk = ({additional_work}) => {
    let combinedTotalHours = 0;
    let combinedSpendHours = 0;

    if(additional_work){
        additional_work.forEach(element => {
            combinedSpendHours = combinedSpendHours + element.total_agreed;
            combinedTotalHours = combinedTotalHours + element.total_hours;
        });
    }

    return(
        <div className="totaalmeerwerk">
            <h3 className="totaalmeerwerk__h3">Totaal meerwerk</h3>
            <div className="totaalmeerwerk__wrapper">
                <div className="totaalmeerwerk__wrapper__bar"><b  className="totaalmeerwerk__wrapper__bar--bold">{combinedSpendHours}/{combinedTotalHours} uur </b>goedgekeurd</div>
                <a className="totaalmeerwerk__wrapper__button btn" href="#meerwerk">Bekijk meerwerk</a>
            </div>
        </div>
    )
}

export default TotaalMeerwerk;