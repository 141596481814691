import axios from "axios";
import "./CreateItemPopup.scss";
import axiosClient from "../../../axios-client";
import { useStateContext } from "../../../contexts/ContextProvider";

const CreateItemPopup = ({ fetchItems, baseIssues, setBaseIssues, setCreateIssuePopupOpen }) => {
    const {token} = useStateContext();

    const createIssue = (e) =>{
        e.preventDefault();
        const title = e.target.title.value;
        const description = e.target.description.value;
        const newIssue = {
            token: 'TempTokenWebvalue3',
            apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
            title: title,
            description: description,
            project_id: 4760,
        }
        axiosClient({
            method: 'post',
            url: `/api/v1/Items/`,
            params: {
                apiKey: 'bfc2fa70-dfe4-4bb5-a78b-fee2937b6a7e',
                token: token,
                submit: 'submit'
            },
            request: newIssue
        }).then(function (response) {
            console.log(response);
            fetchItems();
          })
          .catch(function (error) {
            console.log(error);
            });
        setCreateIssuePopupOpen(false);
    }

    return (
        <div className="createissuepopup">
            <div onClick={()=>setCreateIssuePopupOpen(false)} className="createissuepopup__left"></div>
            <div className="createissuepopup__right">
                <header className="createissuepopup__right__header">
                    <h1 className="createissuepopup__right__header__title">Nieuw item</h1>
                    <i onClick={()=>setCreateIssuePopupOpen(false)} class="fa-solid fa-xmark createissuepopup__right__header__close"></i>
                </header>
                <form className="createissuepopup__right__form" onSubmit={(e)=>createIssue(e)}>
                    <div className="createissuepopup__right__form__inputwrapper">
                        <label className="createissuepopup__right__form__inputwrapper__label" htmlFor="title">Titel</label>
                        <input className="createissuepopup__right__form__inputwrapper__input" type="text" name="title" id="title" placeholder="Title" maxlength="40"/>
                    </div>
                    <div className="createissuepopup__right__form__textareawrapper">
                        <label className="createissuepopup__right__form__textareawrapper__label" htmlFor="description">Beschrijf uw probleem</label>
                        <textarea  className="createissuepopup__right__form__textareawrapper__textarea" name="description" id="description" placeholder="Description"></textarea>
                    </div>
                    <button className="createissuepopup__right__form__button btn" type="submit">Create</button>
                </form>
            </div>
        </div>
    );
}

export default CreateItemPopup;