import "./SignaturePopup.scss";
import signature from "../../assets/signature.svg";
import {useEffect, useState} from "react";

const SignaturePopup = ({signaturePopupOpen, switchSignaturePopupOpen}) =>{
    const [saveSignature, setSaveSignature] = useState(false);
    //var iframe = document.getElementById("iframe")

    // let callToBeExecuted = false;
    // if (iframe.contentWindow !== null && !callToBeExecuted){
    //    callToBeExecuted = true;
    //    var svg = document.getElementById('iframe').contentWindow;
    //    svg.setSignature(user.signature)
    // }

    const onSaveSignatureChange = (e) =>{
        setSaveSignature(e.target.checked);
    }


    const onSignatureSubmit = () =>{
        // var svg = document.getElementById('iframe').contentWindow;

        // if(saveSignature){
        //     axiosClient.put(`/users/${user.id}`, userToBeAdded)
        //         .then(()=>{
        //             setNotification("User was succesfully updated")
        //         })
        //         .catch(err =>{
        //             const response = err.response;
        //             if(response && response.status === 422){
        //                 setErrors(response.data.errors)
        //             }
        //         })
        // }
        // onBack();
    }

    const onBack = () =>{
        switchSignaturePopupOpen();
    }


    function showSignature() {
        var svg = document.getElementById('iframe').contentWindow;
        var pathdata = document.getElementById('pathdata');
        pathdata.textContent = svg.getSignature();
    }

    function clearSignature() {
        var svg = document.getElementById('iframe').contentWindow;
        var pathdata = document.getElementById('pathdata');
        svg.clearSignature();
        pathdata.textContent = '';


    }


    if(signaturePopupOpen){
        return(
            <div className="SignaturePopup is-open">
                <div className="clickablebackground" onClick={onBack}></div>
                <form className="SignaturePopup__form" onSubmit={(e) => {e.preventDefault()}} action={() => onSignatureSubmit}>
                    <div className="SignaturePopup__form__header">
                        <h1 className="SignaturePopup__form__header--h1">Onderteken uw offerte</h1>
                        <i onClick={onBack} className="fa-solid fa-xmark signaturePopup__form__header__back"></i>
                    </div>
                    <input type="hidden" id="js--user-signature"/>
                    <div className="SignaturePopup__form__inputWrapper">
                        <label className="SignaturePopup__form__inputWrapper--label" htmlFor="signature">Schrijf hieronder uw handtekening</label>
                        <div>
                            <iframe id="iframe" className="SignaturePopup__form__inputWrapper--input" src={signature}></iframe>
                            <div className="SignaturePopup__form__inputWrapper__buttonwrapper">
                                <div className="savesignature">
                                    <input onChange={(e) =>onSaveSignatureChange(e)} className="savesignature__input" name="savesignature" id="savesignature" type="checkbox"/>
                                    <label className="savesignature__label" htmlFor="savesignature"> Save</label>
                                </div>
                                <button className="btn btn--retrysignature" onClick={clearSignature}>Opnieuw</button>
                            </div>
                        </div>


                    </div>
                    <div className="SignaturePopup__form__buttonWrapper">
                        <button onClick={onSignatureSubmit} className="SignaturePopup__form__buttonWrapper--submit">Kostenindicatie goedkeuren</button>
                        <p className="SignaturePopup__form__buttonWrapper--bottomText">
                            Bij handtekening geplaatst gaat u akkoord met de <a href="#kostenindicatie">kostenindicatie.</a>
                        </p>
                    </div>
                    <div>
                        <button className="showsignaturepathdata" onClick={showSignature}>Show signaure path data</button>
                        <div id="pathdata"></div>
                    </div>
                </form>
            </div>
        );
    }else{
        return(
            <div className="SignaturePopup">
                <form className="SignaturePopup__form" onSubmit={(e) => {e.preventDefault()}} action={() => onSignatureSubmit}>
                    <div className="SignaturePopup__form__header">
                        <h1 className="SignaturePopup__form__header--h1">Onderteken uw offerte</h1>
                    </div>
                    <input type="hidden"  id="js--user-signature"/>
                    <div className="SignaturePopup__form__inputWrapper">
                        <label className="SignaturePopup__form__inputWrapper--label" htmlFor="signature">Schrijf hieronder uw handtekening</label>
                        <iframe id="iframe" className="SignaturePopup__form__inputWrapper--input" src={signature}></iframe>
                    </div>
                    <div className="SignaturePopup__form__buttonWrapper">
                        <button onClick={onSignatureSubmit} className="SignaturePopup__form__buttonWrapper--submit">Kostenindicatie goedkeuren</button>
                        <button onClick={onBack} className="SignaturePopup__form__buttonWrapper--back">Terug</button>
                        <p className="SignaturePopup__form__buttonWrapper--bottomText">
                            Bij handtekening geplaatst gaat u akkoord met de
                            <a href="#kostenindicatie"> kostenindicatie.</a>
                        </p>
                    </div>
                    <div>
                        <button onClick={showSignature}>Show signaure path data</button>
                        <button onClick={clearSignature}>Clear signature</button>
                        <div id="pathdata">

                        </div>
                    </div>
                </form>
            </div>
        );
    }



}

export default SignaturePopup;

/*  ---put this where popup goes---


    import { useEffect, useState } from "react";
    import SignaturePopup from "../../Components/Popups/SignaturePopup/SignaturePopup";

    const [signaturePopupOpen, setSignaturePopupOpen] = useState();

    useEffect(()=>{
        setSignaturePopupOpen(false);
    },[])

    const switchSignaturePopupOpen = () =>{
        setSignaturePopupOpen(!signaturePopupOpen);
    }

    return(
        <SignaturePopup signaturePopupOpen={signaturePopupOpen} switchSignaturePopupOpen={switchSignaturePopupOpen}/>
        <button className="btn" onClick={switchSignaturePopupOpen}>Open signature popup</button>
    )
*/
