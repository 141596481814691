import "./ProjectCoordinatie.scss";
import calculatedPercentage from "../../../helpers/calculatedPercentage";

const ProjectCoordinatie =({project_coordination}) =>{

    return(
        <div className="projectCoordinatie">
            <header className="projectCoordinatie__header">
                <h3>Projectcoördinatie</h3>
            </header>
            <div className="projectCoordinatie__progressBar">
                <div style={{width: project_coordination.percentage+"%"}} className="projectCoordinatie__progressBar--progress"><p style={{marginRight: '.5rem'}}><b>{project_coordination.hours_spend}/{project_coordination.hours_total} uur</b> gebruikt{' '}</p>{parseFloat(project_coordination.percentage.toFixed(2))}%</div>
                <div style={{width: (100 - project_coordination.percentage)+"%"}} className="projectCoordinatie__progressBar--progress-left"></div>
            </div>
        </div>
    );
}

export default ProjectCoordinatie;