import "./UrenBalk.scss";

const UrenBalk = (props) =>{

    let hoursToBeRendered = "";
    if(props.completedHours === undefined){
        hoursToBeRendered = String(props.hours) + " uur";
    }else if(props.completedHours > props.hours){
        hoursToBeRendered = <><b className="urenBalk__hours--red">{props.completedHours}</b>/{props.hours} uur</>;
    }else {
        hoursToBeRendered = String(props.hours) + " uur";
    }

    return(
        <div className="urenBalk">
            <div className="urenBalk__info">
                <p className="urenBalk__info--title">{props.text}</p>
                <p className="urenBalk__info--date">{props.date}</p>
            </div>
            <p className="urenBalk__hours">{hoursToBeRendered}</p>
        </div>
    );
}

export default UrenBalk;

/* adding completedHours prop is optional, u can leave it out when the task is not started yet
import UrenBalk from "?";

<UrenBalk text="text" date="00-00-0000" hours={00} completedHours={00}/>
*/